<template>
  <div class="grid w-full min-h-screen grid-cols-1 md:grid-cols-5">
    <div class="flex flex-col h-full col-span-1 md:col-span-2">
      <div class="flex-1 h-full pt-10 sm:pt-20 px-3 sm:px-0 ">
        <div class="max-w-md px-2 mx-auto">
          <img src="../../../assets/images/dscvry_logo-purple.png" class="h-6"/>
          <Text content="Reset Password" customClass="mt-8 tracking-tight" weight="medium" size="2xl" color="gray-800"/>
        </div>
        <div class="flex flex-col h-full mt-4">
          <div class="max-w-md px-2 mx-auto space-y-6 w-full">
            <Text content="Enter your email address to reset your password" color="gray-800"/>
            <div class="-space-y-px rounded-md">
              <Input :isError="inputs.email.error" :on-enter-pressed="() => $emit('onSubmit')"
                     :help-text="inputs.email.error" id="email" v-model:value="inputs.email.value"
                     :rounded="inputs.email.rounded" :type="inputs.email.type" :customClass="inputs.email.customClass"
                     :label="inputs.email.label" :placeholder="inputs.email.placeholder"/>
            </div>
          </div>

        </div>
      </div>
      <div class="flex-none">
        <div class="mb-20 sm:mb-0">
          <div class="max-w-md mx-auto my-6 px-5 sm:px-2 flex space-x-2">
            <Text content="Return to " color="gray-800" size="sm"/>
            <Text @click="$emit('goTo', '/login')" content="Log In" color="primary-600"
                  customClass="cursor-pointer self-start flex underline text-sm"/>
          </div>
          <div class="fixed bottom-0 xs:relative sm:relative w-full py-3 border-t bg-gray-50 px-3 sm:px-0 ">
            <div class="flex justify-end max-w-md px-2 mx-auto">
              <Button :content="button.label" variant="primary" :disabled="!isValid" :onClick="() => $emit('onSubmit')"
                      :is-loading="isLoading" :custom-class="disabled ? 'bg-gray-500' : ''"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden h-full col-span-1 md:col-span-3 md:block">
      <Image :src="image.src" :alt="image.alt" width="full" height="screen" customClass="object-cover"/>
    </div>
  </div>
</template>

<script>
/**
 - Combobox with text input to filter onh    basis of name property on default
 - give filter key to provide filter on a custom property
 **/
import Text from '../../atoms/Text/Text.vue';
import Link from '../../atoms/Link/Link.vue';
import Checkbox from '../../molecules/Inputs/Checkbox/Checkbox.vue';
import Image from '../../atoms/Image/Image.vue';
import Input from '../../molecules/Inputs/Component/Component.vue';
import Button from '../../atoms/Button/Button.vue';
import Icon from '../../atoms/Icons/Icons.vue';

export default {
  components: {
    Text,
    Link,
    Checkbox,
    Image,
    Input,
    Button,
    Icon
  },
  props: {
    image: {
      type: Object,
      default: () => {
      }
    },
    logo: {
      type: Object,
      default: () => {
      }
    },
    inputs: {
      type: Object,
      default: () => {
      }
    },
    button: {
      type: Object,
      default: () => {
      }
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    inputs: {
      handler(val) {
        this.isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.inputs.email.value)
      },
      deep: true,
    }
  },
  data() {
    return {
      isChecked: false,
      isValid: false,
    }
  },
};
</script>
